import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { CompanyService } from 'src/app/company/services/company.service';
import {
  MyPlannedTransfer,
  Steps,
} from 'src/app/my-transfers/model/my-transfer.model';
import { MyTransfersService } from 'src/app/my-transfers/services/my-transfers.service';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { Inspection, InspectionFile } from '../models/inspection.model';
import { VehicleInspectionResponseModel } from '../models/response.model';
import { Workflow } from '../models/workflow.model';
import { OfflineService } from 'src/app/services/offline.service';

@Injectable({ providedIn: 'root' })
export class VehicleInspectionService {
  public VEHICLE_INSPECTION_LOCAL_STORAGE_KEY = 'vehicleInspectionToken';

  public VEHICLE_INSPECTION_VERSION = '/v1';

  public VEHICLE_INSPECTION_USER_RESOURCE = '/user';
  public VEHICLE_INSPECTION_USER_AUTHENTICATE = '/authenticate';

  public VEHICLE_INSPECTION_INSPECTION_RESOURCE = '/inspection';

  public VEHICLE_INSPECTION_DOCUMENT_RESOURCE = '/document/';
  public VEHICLE_INSPECTION_DOCUMENT_TRANSFER_REFERENCE = 'transfer-reference/';

  public VEHICLE_INSPECTION_UPLOAD_RESOURCE = '/upload/';

  inspection: Inspection;
  inspectionsFile?: InspectionFile;

  step: Steps;
  currentWorkflow?: Workflow;

  baseUrlV1: string;

  public pictureCount: number | undefined;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly storageService: StorageService,
    private readonly myTransfersService: MyTransfersService,
    private readonly profileService: ProfileService,
    private readonly companyService: CompanyService,
    private readonly offlineService: OfflineService,
  ) {
    this.baseUrlV1 =
      environment.vehicleInspectionUrl + this.VEHICLE_INSPECTION_VERSION;

    this.pictureCount = this.pictureCount ? this.pictureCount : 0;
  }

  async authenticate(): Promise<string | undefined> {
    const connected = await this.offlineService.getNetworkStatus();

    if (!connected) {
      return;
    }

    const endpoint =
      environment.vehicleInspectionUrl +
      this.VEHICLE_INSPECTION_VERSION +
      this.VEHICLE_INSPECTION_USER_RESOURCE +
      this.VEHICLE_INSPECTION_USER_AUTHENTICATE;

    const vehicleInspectionResponseModel = await lastValueFrom(
      this.httpClient.post<VehicleInspectionResponseModel<string>>(endpoint, {
        company: this.companyService.company.id,
        driver: this.profileService.profile.id,
        clientKey: environment.vehicleInspectionClientKey,
        clientSecret: environment.vehicleInspectionClientSecret,
      }),
    );

    const token = vehicleInspectionResponseModel.data;

    await this.storeToken(token);

    return token;
  }

  async currentToken(): Promise<string | undefined> {
    return await this.storageService.get(
      this.VEHICLE_INSPECTION_LOCAL_STORAGE_KEY,
    );
  }

  async storeToken(token: string): Promise<void> {
    await this.storageService.set(
      this.VEHICLE_INSPECTION_LOCAL_STORAGE_KEY,
      token,
    );
  }

  async loadInspection(reference: string): Promise<void> {
    await this.fetchInspection(reference);
    await this.readWorkflows();

    this.loadCurrentWorkflow();
  }

  async loadInspections(): Promise<void> {
    await this.readInspectionsFile();

    if (!this.inspectionsFile || !this.inspectionsFileStillValid()) {
      await this.refreshInspectionsFile();
    }
  }

  async saveCurrentWorkflowProgress(workflow: Workflow): Promise<void> {
    try {
      await Filesystem.writeFile({
        path: this.getWorkflowFileName(
          workflow,
          this.myTransfersService.myTransfer,
        ),
        data: JSON.stringify(workflow),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true,
      });

      console.log(
        '[saveCurrentWorkflowProgress] File written successfully',
        workflow.reference,
      );
    } catch (e) {
      console.log('[saveCurrentWorkflowProgress] Unable to write file', e);
    }
  }

  defineCurrentWorkflowByReference(reference: string) {
    if (!reference) {
      return;
    }

    if (reference === this.currentWorkflow?.reference) {
      return;
    }

    this.currentWorkflow = this.findWorkflowByReference(reference);
  }

  async deleteDirectory(directoryPath: string): Promise<void> {
    try {
      await Filesystem.rmdir({
        path: directoryPath,
        directory: Directory.Data,
        recursive: true,
      });
    } catch (error) {
      console.warn(
        '[deleteDirectory] Error when deleting the directory',
        error,
      );
    }
  }

  private async readWorkflow(
    workflow: Workflow,
  ): Promise<Workflow | undefined> {
    let currentWorkflow: Workflow;

    try {
      const result = await Filesystem.readFile({
        path: this.getWorkflowFileName(
          workflow,
          this.myTransfersService.myTransfer,
        ),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      currentWorkflow = JSON.parse(result.data.toString()) as Workflow;

      console.log(
        '[readCurrentWorkflowProgress] File read successfully',
        currentWorkflow,
      );

      return currentWorkflow;
    } catch (error) {
      console.log('[readCurrentWorkflowProgress] Unable to read File', error);
    }

    return;
  }

  private findWorkflowByReference(reference: string): Workflow | undefined {
    const nextWorkflow = this.inspection.workflows.find(
      (workflow) => workflow.reference === reference,
    );

    if (!nextWorkflow) {
      return;
    }

    nextWorkflow.disabled = false;

    return nextWorkflow;
  }

  private async fetchInspection(reference: string): Promise<void> {
    const connected = await this.offlineService.getNetworkStatus();

    if (!connected) {
      this.inspection = this.getInspectionByReferenceFromFile(reference);

      return;
    }

    const token = await this.currentToken();

    if (!token) {
      throw new Error('movacarpro_vehicle_inspection_unauthorized');
    }

    const endpoint =
      this.baseUrlV1 +
      this.VEHICLE_INSPECTION_INSPECTION_RESOURCE +
      '/' +
      reference;

    this.inspection = await firstValueFrom(
      this.httpClient.get<Inspection>(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    );
  }

  private async fetchInspections(): Promise<
    VehicleInspectionResponseModel<Inspection[]>
  > {
    const token = await this.currentToken();

    if (!token) {
      throw new Error('movacarpro_vehicle_inspection_unauthorized');
    }

    const endpoint =
      this.baseUrlV1 + this.VEHICLE_INSPECTION_INSPECTION_RESOURCE;

    return await firstValueFrom(
      this.httpClient.get<VehicleInspectionResponseModel<Inspection[]>>(
        endpoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    );
  }

  private async readWorkflows() {
    for (let [index, workflow] of this.inspection.workflows.entries()) {
      const currentWorkflow = await this.readWorkflow(workflow);

      if (!currentWorkflow) {
        continue;
      }

      currentWorkflow!.disabled = false;

      this.currentWorkflow = currentWorkflow;
      this.inspection.workflows[index] = currentWorkflow;
    }
  }

  private getWorkflowFileName(
    workflow: Workflow,
    myPlannedTransfer: MyPlannedTransfer,
  ) {
    if (!workflow) {
      throw Error('movacarpro_vehicle_inspection_undefined_workflow');
    }

    return myPlannedTransfer.id + '/' + this.step + '/' + workflow.reference;
  }

  private loadCurrentWorkflow() {
    if (this.currentWorkflow) {
      return;
    }

    const firstWorkflow = this.inspection.workflows.find(
      (workflow: Workflow): boolean => workflow.isFirst,
    );

    firstWorkflow!.disabled = false;

    this.currentWorkflow = firstWorkflow;
  }

  private buildInspectionsFile(inspections: Inspection[]): InspectionFile {
    const inspectionRecords = inspections.reduce(
      (accumulator, inspection) => {
        accumulator[inspection.reference] = inspection;

        return accumulator;
      },
      {} as Record<string, Inspection>,
    );

    return {
      createdAt: new Date(),
      inspections: inspectionRecords,
    };
  }

  private async saveInspectionsFile(
    inspectionFile: InspectionFile,
  ): Promise<void> {
    try {
      await Filesystem.writeFile({
        path: 'inspections',
        data: JSON.stringify(inspectionFile),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      console.log(
        '[saveInspectionsFile] File written successfully',
        this.inspection,
      );
    } catch (e) {
      console.log('[saveInspectionsFile] Unable to write file', e);
    }
  }

  private async readInspectionsFile(): Promise<void> {
    try {
      const result = await Filesystem.readFile({
        path: 'inspections',
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      this.inspectionsFile = JSON.parse(
        result.data.toString(),
      ) as InspectionFile;

      this.inspectionsFile.createdAt = new Date(this.inspectionsFile.createdAt);

      console.log('[readInspectionsFile] File read successfully');
    } catch (error) {
      console.log('[readInspectionsFile] Unable to read File', error);
    }
  }

  private async refreshInspectionsFile(): Promise<void> {
    await this.authenticate();

    const inspections = await this.fetchInspections();

    if (!inspections.data) {
      return;
    }

    const inspectionRecords = this.buildInspectionsFile(inspections.data);

    try {
      await this.saveInspectionsFile(inspectionRecords);
      await this.readInspectionsFile();
    } catch (error) {
      throw error;
    }
  }

  private inspectionsFileStillValid(): boolean {
    if (!this.inspectionsFile) {
      return false;
    }

    const oneDay = 24 * 60 * 60 * 1000;
    const now = new Date().getTime();
    const fileCreatedAt = this.inspectionsFile.createdAt.getTime();
    const interval = Math.abs(now - fileCreatedAt);

    return interval <= oneDay;
  }

  private getInspectionByReferenceFromFile(reference: string): Inspection {
    const inspection = JSON.parse(
      JSON.stringify(this.inspectionsFile!.inspections[reference]),
    );

    return inspection;
  }
}
